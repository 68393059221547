@font-face {
	font-family: 'gt-eesti-medium';
	src: url("./fonts/gt-eesti-medium.woff") format("woff");
}

@font-face {
	font-family: 'gt-eesti-regular';
	src: url("./fonts/gt-eesti-regular.woff") format("woff");
}

@font-face {
	font-family: 'Cubano';
	src: url("./fonts/cubano.woff") format("woff");
}

#body {
	font-family: gt-eesti-regular, "Helvetica Neue", Helvetica, sans-serif;
	margin: 0px;
	display: flex;
	flex-direction: row;
}

.backgroundBlue {
	background-color: #F2F6FA;
}

.noData {
	background-color: #F2F6FA;
	color: #8798AC;
	padding: 30px;
	text-align: center;
}

.fileInputContent {
	background-color: #F2F6FA;
	color: #8798AC;
	padding: 10px;
	box-shadow: inset 0 1px 1px 0 #C3CCD7;
	border-color: #ADB9C6;
	border-radius: 2px;
	border-style: solid;
	border-width: 1px;
	cursor: pointer;
	display: inline-block;
}

.fileInputContainer form {
	margin-bottom: 0px;
}

.clickToSelectFile {
	padding: 30px;
	text-align: center;
	display: block;
}

a,
.actionButton {
	color: #3ea065;
	text-decoration: none;
	cursor: pointer;
	display: inline;
}

input,
select,
button {
	font-family: inherit;
}

:focus {
	outline: 0;
}

.view {
	height: 100vh;
	flex-grow: 1;
	overflow-y: auto;
}

.sidebar {
	background-color: #425060;
	height: 100vh;
	box-sizing: border-box;
	padding: 15px;
	width: 260px;
	min-width: 260px;
	overflow-y: auto;
	padding-bottom: 60px;
	position: relative;
}

.sidebarItem {
	padding: 20px;
	color: #ADB9C6;
	cursor: pointer;
	white-space: nowrap;
	position: relative;
}

.sidebarItem:hover {
	color: white;
}

.sidebarItem.active {
	color: white;
	background-color: #536579;
}

[data-subview-index] {
	font-size: 13px;
	padding: 10px 0px 10px 30px;
	color: #ADB9C6 !important;
}

.subviewsSeparator {
	padding-top: 20px;
}

[data-subview-index]:hover,
[data-subview-index].active {
	color: white !important;
}

.sidebarIcon {
	width: 20px;
	color: #647A93;
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
	text-wrap: wrap;
}

.sidebarLabel {
	display: inline-block;
	vertical-align: middle;
}

table {
	border-collapse: collapse;
	width: 100%;
}

.record {
	height: 47px;
}

.viewHeaderActions {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: flex-end;
}

table thead th {
	text-align: left;
	background-color: #303B47;
	color: #ADB9C6;
	padding: 15px;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: .83px;
	height: 43px;
}

table tbody td {
	padding: 15px;
	transition: all .1s ease-out;
	color: #425060;
}

table tbody tr:nth-child(2n) td {
	background-color: #F2F6FA;
}

table tbody tr:hover:not(.nonClickableRow) td {
	background: #647A93;
	color: white;
	cursor: pointer;
}

tr:hover a {
	color: white;
}

.panelTable tr:hover a {
	color: #3ea065;
}

.viewHeader {
	box-shadow: 0 1px 0 rgba(0, 0, 0, .1);
	padding: 20px 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 70px;
	box-sizing: border-box;
	background: white;
	gap: 20px;
}

.panelHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 25px;
	box-sizing: border-box;
}

.viewTitle,
.panelTitle {
	font-family: Cubano, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 20px;
	text-transform: uppercase;
}

.showContainer {
	display: flex;
	flex-direction: row;
	flex: 1;
}

.detailsPanel {
	flex: 2;
}

.recordViewPanels {
	margin-left: 20px;
	flex: 1;
}

.panelTitle {
	margin-bottom: 25px;
}

.panel {
	border-radius: 4px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .15);
	background-color: #fff;
	padding: 15px;
	position: relative;
	margin-bottom: 20px;
	overflow: auto;
}

.sidebarTitle {
	color: white;
	text-align: center;
	font-weight: 500;
	font-size: 22px;
	margin-top: 30px;
	margin-bottom: 10px;
}

.sidebarSubtitle {
	color: white;
	text-align: center;
	margin-bottom: 40px;
	color: #ADB9C6;
}

.sidebarBranding {
	position: fixed;
	bottom: 0px;
	width: 260px;
	text-align: center;
	color: #647A93;
	height: 50px;
	line-height: 50px;
	left: 0px;
	font-size: 14px;
	/* z-index: 0; */
}

.button,
.button:hover,
.button:active,
.swal-button,
.swal-button:hover,
.swal-button:active {
	display: inline-block;
	cursor: pointer;
	border-radius: 2px;
	padding: 10px 22px;
	background-color: #F2F6FA;
	color: #536579;
	font-size: 14px;
	margin-right: 8px;
}

.button.openBulkActionsButton {
	padding: 7px 22px;
	margin-left: 16px;
}

.smallButton,
.smallButton:hover {
	padding: 5px 22px;
}

.swal-button,
.swal-button:hover,
.swal-button:active {
	margin-right: 0px;
}

.swal-button--danger,
.swal-button--danger:hover,
.swal-button--danger:active {
	background-color: #e64942;
	color: white;
}

.swal-button:focus {
	box-shadow: none;
}

.button.loading {
	opacity: 0.5;
	cursor: default;
}

.downloadButton,
.uploadButton,
.downloadPanelButton,
.uploadPanelButton {
	display: inline-block;
	cursor: pointer;
	border-radius: 2px;
	font-size: 12px;
	color: #647A93;
	background-color: #F2F6FA;
	width: 30px;
	height: 30px;
	text-align: center;
	justify-content: center;
	display: flex;
	align-items: center;
	line-height: 30px;
	margin-left: 5px;
	flex-shrink: 0;
}

.plusButton {
	margin-left: 5px;
	display: inline-block;
	cursor: pointer;
	border-radius: 2px;
	font-size: 14px;
	background: #45B271;
	color: white;
	width: 30px;
	height: 30px;
	text-align: center;
	justify-content: center;
	display: flex;
	align-items: center;
	line-height: 30px;
}

.alternativePlusButton {
	margin-left: 5px;
	display: inline-block;
	cursor: pointer;
	border-radius: 2px;
	font-size: 14px;
	background: #45B271;
	color: white;
	padding: 0px 10px;
	height: 30px;
	text-align: center;
	justify-content: center;
	display: flex;
	align-items: center;
	line-height: 30px;
}

.greenButton,
.greenButton:hover,
.greenButton:active {
	background: #45B271;
	color: white;
}

.paddedView {
	padding: 30px;
}

.formElement {
	margin-bottom: 30px;
}

.showRecordField {
	margin-bottom: 20px;
}

.formLabel,
.filterLabel {
	color: #8798AC;
	font-size: 13px;
	font-weight: 500;
	margin-bottom: 6px;
	text-transform: uppercase;
}

.formInput input,
.formInput select,
.formInput textarea,
.editLocalesTable input,
.editLocalesTable textarea,
.ql-editor,
.addFilterPopup input,
.addFilterPopup select {
	background-color: #F2F6FA;
	border-color: #ADB9C6;
	border-radius: 2px;
	border-style: solid;
	border-width: 1px;
	box-shadow: inset 0 1px 1px 0 #C3CCD7;
	color: #536579;
	padding: 0 15px;
	width: 100%;
	font-size: 14px;
	box-sizing: border-box;
	display: block;
}

.ql-editor {
	padding: 15px;
}

.addFilterPopup input,
.addFilterPopup select {
	padding: 10px;
	margin-bottom: 10px;
}

.formInput textarea {
	height: 150px;
	padding: 15px;
}

input {
	box-sizing: border-box;
}

.formInput input,
.formInput select,
.editLocalesTable input {
	height: 38px;
	min-height: 38px;
	transition: all 250ms ease-out;
}

textarea {
	resize: none;
	font-family: inherit;
}

.editLocalesTable textarea {
	line-height: 1.7;
	padding: 5px 15px;
}

.formValue {
	color: #607083;
}

.formValue p,
.formValue ul,
.formValue h2,
.formValue h1,
.formValue h3 {
	margin: 0;
}

.scrollable {
	height: calc(100vh - 40px);
	overflow-y: auto;
	position: relative;
}

.tableFooter {
	height: 40px;
	box-sizing: border-box;
	background-color: #303B47;
	color: #ADB9C6;
	padding: 0px 15px;
	display: flex;
	justify-content: space-between;
	text-transform: uppercase;
	align-items: center;
	font-size: 12px;
	font-family: gt-eesti-medium, "Helvetica Neue", Helvetica, sans-serif;
	letter-spacing: .83px;
}

.paginator {
	display: flex;
	flex-direction: row;
}

.paginatorNumber {
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	color: #ADB9C6;
	padding: 0px 10px;
	height: 20px;
	align-items: center;
	display: flex;
	white-space: nowrap;
}

.paginatorLeft,
.paginatorRight {
	opacity: 0.2;
	cursor: pointer;
	border: 1px solid white;
	font-size: 13px;
	height: 20px;
	align-items: center;
	display: flex;
	width: 20px;
	justify-content: center;
}

.fakeRecord {
	height: 10px;
	background-color: #F2F6FA;
	width: 80%;
}

thead .fakeRecord {
	background-color: #ADB9C6;
}

table tbody tr:nth-child(2n) .fakeRecord {
	background-color: #D0D8E0;
}

.actionButtonsContainer {
	position: relative;
	display: inline-block;
}

.actions,
.bulkActions {
	position: absolute;
	right: 8px;
	top: 37px;
	z-index: 1;
	box-shadow: 0 2px 40px 0 rgba(49, 47, 51, .3);
}

.bulkActions {
	top: 31px;
}

.red {
	color: #FB6669 !important;
}

.action {
	cursor: pointer;
	background: white;
	border-bottom: 1px solid #F2F6FA;
	color: #647A93;
	font-size: 14px;
	line-height: 14px;
	min-width: 150px;
	padding: 10px 12px;
	text-align: left;
	white-space: nowrap;
	box-sizing: border-box;
}

.swal-overlay--show-modal .swal-modal {
	animation: none;
}

.swal-overlay {
	transition: none;
}

.swal-title {
	color: #607083;
	font-weight: normal;
}

.swal-modal {
	border-radius: 2px;
}

.swal-text {
	color: #8798AC;
}

.searchBarContainer {
	position: relative;
	display: flex;
	max-width: 430px;
	width: 100%;
}

.searchBar {
	background-color: #F2F6FA;
	border-color: #ADB9C6;
	border-radius: 2px;
	border-style: solid;
	border-width: 1px;
	color: #536579;
	display: flex;
	height: 30px;
	overflow: hidden;
	flex: 1;
	box-shadow: inset 0 1px 1px 0 #C3CCD7;
	padding-left: 30px;
}

.searchBarIcon {
	position: absolute;
	color: #647A93;
	top: 0px;
	left: 0px;
	height: 30px;
	display: flex;
	width: 30px;
	align-items: center;
	justify-content: center;
}

.map {
	height: 300px;
}

.galleryPicture {
	width: calc(50% - 4px);
	margin-bottom: 8px;
	position: relative;
	display: inline-block;
}

.deleteIcon,
.deleteFile {
	cursor: pointer;
	border-radius: 2px;
	font-size: 14px;
	background-color: #F2F6FA;
	color: #536579;
	text-align: center;
	justify-content: center;
	display: none;
	align-items: center;
	width: 30px;
	height: 30px;
	line-height: 30px;
}

.galleryPicture .deleteIcon {
	position: absolute;
	top: 8px;
	right: 8px;
}

.record:hover .deleteIcon {
	display: flex;
}

.deleteIconCell {
	box-sizing: border-box;
	width: 40px;
	padding: 0px !important;
}

.galleryPicture:hover .deleteIcon {
	display: flex;
}

.galleryPicture img {
	width: 100%;
}

.galleryPicture:nth-child(2n + 1) {
	margin-right: 8px;
}

.sortable {
	cursor: pointer;
}

.columnSortIcon {
	padding-left: 8px;
	display: inline;
}

.sortable:hover .columnSortIcon {
	color: white;
}

.colorPreview {
	width: 80px;
	height: 30px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, .1);
}

iframe {
	width: 100%;
	margin: 0;
	box-sizing: border-box;
	height: 100%;
	border: none;
	display: block;
}

.flagContainer {
	padding-top: 0px;
	padding-bottom: 0px;
	height: 44px;
}

.flag {
	height: 22px;
	vertical-align: middle;
	margin-right: 8px;
	margin-left: 2px;
}

.flagLabel {
	vertical-align: middle;
	display: inline-block;
}

.localesTableHeaderContainer {
	background-color: #303B47;
}

.localesTableHeader,
.localesTable,
.editLocalesTable {
	width: 100%;
	table-layout: fixed;
}

.localesTableHeader {
	width: calc(100% - 17px);
}

.localesTableContainer {
	height: calc(100vh - 114px);
	overflow-y: scroll;
}

.folded {
	display: none;
}

.quill {
	height: 200px;
}

.ql-container.ql-snow {
	border: none;
}

.dashboardView {
	flex-wrap: wrap;
	flex: 1;
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
	box-sizing: border-box;
}

.dashboardPanel {
	border-radius: 4px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .15);
	background-color: #fff;
	padding: 15px;
	position: relative;
	margin-bottom: 20px;
	margin-right: 20px;
	flex: 0 1 calc(33.33% - 50px);
}

.dashboardLegend {
	color: rgb(66, 80, 96);
	margin-bottom: 15px;
}

.dashboardValue {
	font-size: 30px;
	color: rgb(31, 38, 46);
}

.addFilterPopup {
	position: absolute;
	box-shadow: 0 2px 40px 0 rgba(49, 47, 51, .3);
	background: white;
	padding: 15px;
	right: 0px;
	top: 31px;
	width: 280px;
}

.openFilterPopup {
	padding: 6px 22px !important;
	margin-left: 8px !important;
	margin-right: 0px !important;
	position: relative;
	white-space: nowrap;
}

.openFilterPopup i {
	margin-right: 8px;
	font-size: 12px;
}

.filtersContainer {
	padding: 12px;
}

.filterItem {
	color: white;
	background-color: #647A93;
	font-size: 12px;
	text-transform: uppercase;
	display: inline-block;
	border-radius: 2px;
	margin-right: 8px;
	overflow: hidden;
}

.filterItemLabel {
	display: inline-block;
	padding: 4px;
	height: 22px;
	box-sizing: border-box;
}

.filterRemove {
	padding: 4px;
	cursor: pointer;
	display: inline-block;
	background-color: #536579;
	height: 22px;
	box-sizing: border-box;
}

.logo {
	width: 60%;
	left: 20%;
	position: relative;
	top: 10px;
}

.notificationWrapper {
	color: white;
	background-color: #F44336;
	border-radius: 50%;
	width: 15px;
	height: 15px;
	position: absolute;
	justify-content: center;
	align-items: center;
	top: 31px;
	left: 28px;
	font-size: 11px;
	display: none;
}

[data-subview-index] {
	position: relative;
}

.notificationWrapperForSubview {
	color: white;
	background-color: #F44336;
	border-radius: 4px;
	padding: 0px 5px;
	height: 15px;
	position: absolute;
	justify-content: center;
	align-items: center;
	top: 10px;
	right: 0px;
	font-size: 11px;
	display: none;
}

.checkbox {
	width: 15px;
	height: 15px;
	background-color: white;
	border: 1px solid black;
	border-radius: 3px;
}

.checkbox.checked {
	position: relative;
	background-color: #45B271;
}

.checkbox.checked::after {
	position: absolute;
	font-family: "Font Awesome 5 Pro";
	content: "\f00c";
	color: white;
	font-size: 14px;
}

.fileInputContent {
	position: relative;
}

.fileInputContent .deleteFile {
	position: absolute;
	bottom: 20px;
	right: 20px;
}

.fileInputContent .nonImageFileOutput .deleteFile {
	bottom: 4px;
	right: 4px;
}

.fileInputContent:hover .deleteFile {
	display: flex;
}

.labelTooltipContainer {
	position: relative;
	display: inline-block;
	flex-direction: row;
}

.labelTooltipIcon {
	padding-left: 5px;
}

.tooltip {
	position: absolute;
	top: -10px;
	left: 25px;
	background: white;
	padding: 10px;
	box-shadow: 0 1px 3px 0 #C3CCD7;
	font-size: 12px;
	min-width: 80px;
	z-index: 1;
	text-transform: lowercase;
}


.loginPanelBackground {
	font-family: gt-eesti-regular, "Helvetica Neue", Helvetica, sans-serif;
	margin: 0px;
	display: flex;
	height: 100vh;
	justify-content: center;
	align-items: center;
	background: #233550;
	flex: 1;
}

.appLoading {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	color: #233550;
	font-size: 72px;
	height: 100vh;
}

.loginLogoContainer {
	text-align: center;
}

.loginLogo {
	width: 180px;
	margin-bottom: 30px;
}

.loginPanel {
	background: white;
	border-radius: 2px;
	padding: 30px;
	border: 1px solid #EBEDF8;
	max-width: 250px;
	position: relative;
}

.loginPanel label {
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 7px;
	color: #647A93;
	font-size: 12px;
	text-transform: uppercase;
}

.loginPanel input {
	width: 100%;
	margin-top: 5px;
	margin-bottom: 25px;
	font-family: inherit;

	background-color: #F2F6FA;
	border-color: #ADB9C6;
	border-radius: 2px;
	border-style: solid;
	border-width: 1px;
	box-shadow: inset 0 1px 1px 0 #C3CCD7;
	color: #536579;
	height: 38px;
	min-height: 38px;
	padding: 0 15px;
	width: 100%;
	font-size: 14px;
}

.loginPanel button {
	display: inline-block;
	cursor: pointer;
	border-radius: 2px;
	padding: 10px 22px;
	font-size: 14px;
	width: 100%;
	border: none;
	font-family: inherit;
}

.ck-content {
	min-height: 200px;
}

.richTextStats {
	background-color: #f4f5f8;
	color: hsl(225 22% 45% / 1);
	padding: 5px 10px;
	font-size: 14px;
}

.message {
	padding: 10px 10px 25px;
	border-radius: 5px;
	width: 300px;
	position: relative;
	margin-bottom: 10px;
	white-space: pre-wrap;
}

.messagesContainer {
	display: flex;
	flex-direction: column;
}

.message.adminMessage {
	background-color: #3F51B5;
	color: white;
	align-self: flex-end;
}

.message.adminMessage::before {
	content: "";
	position: absolute;
	bottom: -7px;
	right: 10px;
	border-width: 10px 10px 0;
	border-style: solid;
	border-color: #3F51B5 transparent transparent;
}

.message.userMessage {
	background-color: #f2f6fa;
	color: black;
	float: left;
}

.message.userMessage::before {
	content: "";
	position: absolute;
	bottom: -8px;
	left: 10px;
	border-width: 10px 10px 0;
	border-style: solid;
	border-color: #f2f6fa transparent transparent;
}

.message .messageDate {
	font-size: 11px;
	position: absolute;
	right: 6px;
	bottom: 6px;
	opacity: 0.8;
}

.sendMessageContainer {
	display: flex;
	flex-direction: row;
	margin-top: 10px;
}

.sendMessageInput {
	width: 100%;
	padding: 10px;
	border-radius: 5px;
	border: none;
	background-color: #F2F6FA;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	font-size: 16px;
	height: 75px;
}

.sendButton {
	background-color: #45B271;
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.menu {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 10px;
	left: 10px;
	color: #ADB9C6;
}

.sidebarToggle {
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 1;
	background-color: white;
	color: #233550;
	box-shadow: 0 2px 40px 0 rgba(49, 47, 51, .3);
	padding: 10px;
	border-radius: 10px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	cursor: pointer;
}

.menuOptions {
	position: absolute;
	left: 0;
	top: 100%;
	background-color: white;
	border-radius: 2px;
	width: 180px;
	box-shadow: 0 2px 40px 0 rgba(49, 47, 51, .3);
	z-index: 1000;
}

.menuButton {
	cursor: pointer;
	padding: 10px;
}

.menuButton:hover {
	opacity: 0.8;
}

.menuOption {
	padding: 10px 15px;
	cursor: pointer;
	display: flex;
	align-items: center;
	font-size: 14px;
	color: #333;
}

.menuOption:hover {
	background-color: #f5f5f5;
}

.menuOption i {
	margin-right: 8px;
}